<script setup>
    import { ref, onMounted } from 'vue'

    import { Link, router } from '@inertiajs/vue3'
    import { usePage } from '@inertiajs/vue3'
    import MarketingFlyout from '@/Components/scene/MarketingFly.vue'
    import useSWRV from 'swrv'
    import posthog from 'posthog-js'
    import { useTagsStore } from '@/store/tagsStore'

    const tagsStore = useTagsStore()

    const resetAndNavigate = () => {
        // console.log('resetAndNavigate')
        tagsStore.resetStore() // Reset the store
        router.visit(route('explore.index')) // Navigate
    }

    let pathName = window.location.pathname
    const showMarketingFlyout = ref(false)
    const openMarketingFlyout = () => {
        showMarketingFlyout.value = true
    }

    const goMessagePage = () => {
        router.visit(route('message'))
    }

    const closeMarketingFlyout = () => {
        showMarketingFlyout.value = false
    }
    const props = usePage().props

    const { isLoggedIn, avatarImage, isCreator, pathNameProp, reff } = defineProps({
        isLoggedIn: {
            type: Boolean,
            default: false,
        },

        pathNameProp: {
            type: String,
            default: undefined,
        },

        reff: {
            type: String,
            default: undefined,
        },
    })
    const fetcher = url => axios.get(url).then(res => res.data)
    const { data: profile } = useSWRV(isLoggedIn ? '/profile/resource' : null, fetcher)

    const backreff = usePage().props.backref.includes('message')

    import { initFlowbite } from 'flowbite'

    onMounted(() => {
        pathNameProp
        initFlowbite
    })

    const handleClickOnJoinFree = () => {
        posthog.capture('clicked_join_free', {
            path: pathName,
        })

        if (pathNameProp === '/message') {
            fathom.trackEvent('exn_lander_join_nav_click')
        }
    }
</script>
<template>
    <div
        class="hidden h-[67px] min-w-[363px] align-top sm:flex sm:items-center sm:justify-end lg:justify-evenly">
        <div
            class="relative flex gap-[25px] lg:min-w-[340px] lg:gap-[25px] xl:min-w-[360px] xl:gap-[38px]"
            v-if="isLoggedIn">
            <div @click="resetAndNavigate" title="Explore" alt="Explore" class="flex items-center">
                <font-awesome-icon
                    title="Explore"
                    alt="Explore"
                    :icon="['fas', 'magnifying-glass']"
                    class="mx-auto h-[27px] w-[27px] cursor-pointer text-iconWhite hover:text-gray-400 dark:text-white dark:hover:text-mintgreen" />
            </div>
            <Link
                :href="route('notifications')"
                title="notifications"
                alt="notifications"
                class="relative flex items-center">
                <font-awesome-icon
                    title="notifications"
                    alt="notifications"
                    :icon="['fas', 'bell']"
                    class="mx-auto h-[30px] w-[26px] cursor-pointer text-iconWhite hover:text-gray-400 dark:text-white dark:hover:text-mintgreen" />
                <div
                    v-if="profile?.notifications_count > 0"
                    class="absolute left-[15px] top-[4px] h-[19px] w-[19px] rounded-full border-[2px] border-alertred bg-alertred dark:border-alertred">
                    <span
                        class="absolute left-[1px] top-0 h-[16px] w-[15px] text-center text-[10px] font-medium leading-[16px] text-white"
                        >{{
                            profile?.notifications_count > 9 ? '+9' : profile?.notifications_count
                        }}</span
                    >
                </div>
            </Link>
            <a
                v-if="isLoggedIn"
                title="Messages"
                :href="route('chatify', { origin: pathName })"
                class="relative flex items-center">
                <font-awesome-icon
                    :icon="['fass', 'comment']"
                    class="mx-auto h-[30px] w-[30px] cursor-pointer text-iconWhite dark:text-white dark:hover:text-mintgreen" />
                <div
                    v-if="profile?.conversations_count > 0"
                    class="absolute left-[15px] top-[4px] h-[19px] w-[19px] rounded-full border-[2px] border-alertred bg-alertred dark:border-alertred">
                    <span
                        class="absolute left-[1px] top-0 h-[16px] w-[15px] text-center text-[10px] font-medium leading-[16px] text-white"
                        >{{
                            profile?.conversations_count > 9 ? '+9' : profile?.conversations_count
                        }}</span
                    >
                </div>
            </a>
            <Link
                href="/message"
                v-if="!isLoggedIn"
                title="Messages"
                alt="Messages"
                class="flex items-center">
                <font-awesome-icon
                    :icon="['fass', 'comment']"
                    class="mx-auto h-[30px] w-[30px] cursor-pointer text-iconWhite dark:text-white dark:hover:text-mintgreen" />
            </Link>

            <Link
                :href="route('profile.wallet')"
                class="flex items-center"
                v-if="!props.creator.isCreator">
                <font-awesome-icon
                    :icon="['fas', 'coins']"
                    class="mx-auto h-[30px] w-[30px] cursor-pointer text-iconWhite hover:text-gray-400 dark:text-white dark:hover:text-mintgreen" />
            </Link>
            <Link :href="route('creator.start')" class="flex items-center" v-else>
                <font-awesome-icon
                    :icon="['fas', 'circle-arrow-up']"
                    class="mx-auto h-[30px] w-[30px] cursor-pointer text-iconWhite hover:text-gray-400 dark:text-white dark:hover:text-mintgreen" />
            </Link>
            <!-- Profile Link -->
            <div class="relative flex" v-if="isLoggedIn">
                <Link
                    :href="route('profile.show', props.auth.user.profile_url)"
                    class="inline-flex items-center rounded-md">
                    <button
                        type="button"
                        class="inline-flex h-[30px] w-[30px] items-center rounded-md text-sm font-medium leading-4 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700 focus:outline-none">
                        <img
                            v-if="props.avatarImage"
                            :src="props.avatarImage"
                            class="h-[30px] w-[30px] rounded-full" />
                        <div v-else>
                            <font-awesome-icon
                                :icon="['fa-solid', 'circle-user']"
                                class="mx-auto h-7 w-7 cursor-pointer rounded-full text-white hover:text-gray-400 dark:hover:text-mintgreen" />
                        </div>
                    </button>
                </Link>
            </div>
            <!-- drawer init and toggle -->
            <div class="flex items-center">
                <button
                    class="mx-auto h-[36px] w-[30px] cursor-pointer text-2xl text-iconWhite hover:text-mintgreen dark:text-white dark:hover:text-mintgreen"
                    type="button"
                    data-drawer-target="right-navigation"
                    data-drawer-show="right-navigation"
                    data-drawer-backdrop="true"
                    aria-controls="right-navigation"
                    data-drawer-placement="right">
                    <font-awesome-icon
                        class="h-[36px] w-[9px]"
                        :icon="['fas', 'ellipsis-vertical']" />
                </button>
            </div>
        </div>

        <div
            class="relative flex h-[67px] gap-[25px] lg:gap-[25px] xl:right-[0] xl:gap-[28px]"
            v-if="!isLoggedIn">
            <div @click="resetAndNavigate" class="flex items-center" alt="Explore" title="Explore">
                <font-awesome-icon
                    alt="Explore icon"
                    title="Explore"
                    :icon="['fas', 'magnifying-glass']"
                    class="mx-auto h-[27px] w-[27px] cursor-pointer text-iconWhite hover:text-gray-400 dark:text-white dark:hover:text-mintgreen" />
            </div>

            <a href="/message" class="flex items-center" title="Messages" alt="Messages">
                <font-awesome-icon
                    alt="message icon"
                    :icon="['fass', 'comment']"
                    class="mx-auto h-[30px] w-[30px] cursor-pointer text-iconWhite dark:text-white dark:hover:text-mintgreen" />
            </a>

            <Link :href="route('access')" class="flex items-center" alt="profile">
                <span class="inline-flex rounded-md">
                    <button
                        alt="Profile"
                        title="Profile"
                        type="button"
                        class="inline-flex items-center rounded-md border border-transparent py-2 text-sm font-medium leading-4 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700 focus:outline-none">
                        <div>
                            <font-awesome-icon
                                :icon="['fas', 'circle-user']"
                                class="mx-auto h-[30px] w-[30px] cursor-pointer rounded-full hover:text-mintgreen dark:text-white dark:hover:text-mintgreen" />
                            <span
                                class="absolute left-0 top-[10px] w-full text-center font-semibold capitalize leading-[28px] text-white">
                            </span>
                        </div>
                    </button>
                </span>
            </Link>

            <template v-if="backreff">
                <Link
                    :href="route('access', { type: 'join', reff: 'nav' })"
                    v-if="!isLoggedIn"
                    @click.native.prevent="handleClickOnJoinFree"
                    class="flex items-center">
                    <span
                        alt="Join Free"
                        title="Join Free"
                        class="hidden w-[130px] cursor-pointer rounded-[10px] bg-mintgreen text-center text-[1rem] font-medium leading-[34px] tracking-[0.03rem] dark:text-black dark:hover:bg-hovermintgreen sm:block">
                        Join Free
                    </span>
                </Link>
            </template>

            <template v-else>
                <Link
                    :href="route('access', { type: 'join' })"
                    v-if="!isLoggedIn"
                    @click.native.prevent="handleClickOnJoinFree"
                    class="flex items-center">
                    <span
                        alt="Join Free"
                        title="Join Free"
                        class="hidden w-[130px] cursor-pointer rounded-[10px] bg-mintgreen text-center text-[1rem] font-medium leading-[34px] tracking-[0.03rem] dark:text-black dark:hover:bg-hovermintgreen sm:block">
                        Join Free
                    </span>
                </Link>
            </template>

            <!-- drawer init and toggle -->
            <div class="flex items-center">
                <button
                    class="mx-auto h-[36px] w-[30px] cursor-pointer text-2xl text-iconWhite hover:text-mintgreen dark:text-white dark:hover:text-mintgreen"
                    data-drawer-target="right-navigation"
                    data-drawer-show="right-navigation"
                    data-drawer-backdrop="true"
                    alt="Open Menu"
                    title="Open Menu"
                    aria-controls="right-navigation"
                    data-drawer-placement="right">
                    <font-awesome-icon
                        class="h-[36px] w-[9px]"
                        :icon="['fas', 'ellipsis-vertical']" />
                </button>
            </div>
        </div>
    </div>
    <div class="flex items-center sm:hidden">
        <div class="relative" v-if="isLoggedIn">
            <Link :href="route('notifications')" alt="notifications">
                <font-awesome-icon
                    alt="notifications icon"
                    :icon="['fas', 'bell']"
                    class="block w-6 pt-1 dark:text-white sm:hidden" />
                <span
                    class="dark:border-white-100 absolute left-3 top-0 h-2.5 w-2.5 rounded-full border-white bg-alertred"></span>
            </Link>
        </div>
        <div
            v-if="!isLoggedIn"
            @click="openMarketingFlyout"
            alt="Join Free"
            title="Join Free"
            class="mobile-join cursor-pointer rounded-[10px] bg-mintgreen px-[16px] py-[6px] text-[14px] font-medium leading-[17px]">
            JOIN FREE
        </div>

        <!-- Hamburger -->
        <div class="-mr-2 flex items-center sm:hidden">
            <button
                alt="Open Menu"
                data-drawer-target="right-navigation"
                data-drawer-show="right-navigation"
                data-drawer-backdrop="true"
                aria-controls="right-navigation"
                data-drawer-placement="right"
                class="inline-flex items-center justify-center rounded-md p-3 text-2xl text-gray-300 transition duration-150 ease-in-out hover:text-mintgreen focus:text-mintgreen focus:outline-none">
                <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" />
            </button>
        </div>
    </div>
    <MarketingFlyout
        :show="showMarketingFlyout"
        @close="showMarketingFlyout = false"
        :hideScroll="false" />
</template>
<style scoped>
    .custom-tooltip {
        position: relative;
        cursor: pointer;
    }

    .custom-tooltip::before {
        content: attr(title);
        position: absolute;
        background-color: gray;
        color: white;
        padding: 1px 3px;
        border-radius: 3px;
        font-size: 10px;
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s, visibility 0.2s;
        top: 105%;
        left: 50%;
        transform: translateX(-50%);
    }

    .custom-tooltip:hover::before {
        opacity: 0.9;
        visibility: visible;
    }
</style>
