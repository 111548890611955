<template>
    <PopupDialog
        :show="isHidensfw"
        :hasButtons="false"
        :closeable="false"
        @closeModal="notToClose"
        :isBlured="true">
        <template #bodyContainer>
            <div
                id="dialog"
                class="flex w-full flex-col border-[#444] bg-[#101010] bg-transparent py-4">
                <div class="flex flex-col items-center justify-center space-y-5">
                    <img src="./../../../public/images/18+.png" class="max-w-[80px]" />
                    <h1
                        class="mx-auto my-2 flex w-auto text-center text-xl font-bold text-gray-800 dark:text-gray-100">
                        This Website contains nudity and sexually explicit material.
                    </h1>
                    <div class="max-h-48 overflow-auto">
                        <p class="warning">
                            By clicking on the "I AGREE” button, I represent, warrant and agree to
                            the following:
                        </p>
                        <ol class="warning">
                            <li class="my-4">
                                I am an adult, at least eighteen (18) years old and/or the age of
                                consent in my jurisdiction and have the legal right to access and
                                possess adult material in my community.
                            </li>
                            <li class="my-4">
                                I will not permit any person(s) under eighteen (18) years old or who
                                are otherwise not legally permitted to have access to any of the
                                materials contained on this Website.
                            </li>
                            <li class="my-4">
                                I am voluntarily choosing to access this Website and possibly
                                download the materials on this Website for my own personal use,
                                entertainment, and/or education.
                            </li>
                            <li class="my-4">
                                I am not offended by materials of an adult nature nor do I find such
                                materials objectionable.
                            </li>
                            <li class="my-4">
                                I will exit this Website immediately if I am offended in any way by
                                any material on this Website.
                            </li>
                            <li class="my-4">
                                I will comply with the Terms of Service for this Website. I
                                understand that the Terms of Service governs my use of this Website
                                and I have reviewed and understand the terms.
                            </li>
                            <li class="my-4">
                                I understand that the California Uniform Electronic Transactions Act
                                (Civil Code §§ 1633.1 et seq.) governs this Agreement. I understand
                                that I may take a screenshot of this Agreement or print this page to
                                save the text.
                            </li>
                        </ol>
                    </div>
                </div>

                <div
                    class="mt-5 flex w-full flex-col items-center justify-between space-y-6 sm:my-6 sm:flex-row sm:justify-center sm:space-y-0">
                    <PrimaryButton
                        id="continue"
                        type="button"
                        @click.prevent="setnsfwcookie()"
                        class="dark:text-bg-mintgreen flex h-[48px] w-full max-w-[200px] justify-center border border-mintgreen text-center text-mintgreen hover:bg-mintgreen hover:text-black dark:bg-black dark:hover:bg-mintgreen sm:mr-6 sm:w-[196px] sm:w-[220px]"
                        >I Agree
                    </PrimaryButton>
                    <SecondaryButton
                        id="disagree"
                        type="button"
                        class="my-4 flex h-[48px] w-full max-w-[200px] justify-center text-center hover:bg-gray-200 dark:bg-[#444] dark:text-gray-900 dark:hover:bg-gray-500 sm:w-[196px] sm:w-[220px]"
                        @click.prevent="declinensfw">
                        I Disagree
                    </SecondaryButton>
                </div>
            </div>
        </template>
    </PopupDialog>
</template>
<style scoped>
    #dialog {
        border: 1px solid #444;
        border-radius: 6px;
        background-color: #101010;
        color: #999;
    }
    #continue {
        border-color: #65e8a4;
        color: #65e8a4;
        background-color: #000;
        border-radius: 25px;
    }

    #disagree {
        border-color: #999;
        color: #999;
        background-color: #000;
        border-radius: 25px;
    }

    #disagree:hover {
        background-color: #65e8a4;
        color: #000;
        border-color: #65e8a4;
    }

    #continue:hover {
        background-color: #65e8a4;
        color: #000;
    }

    ol {
        list-style-type: decimal;
        list-style-position: inside;
        padding-left: 20px;
    }

    .warning {
        padding: 0 40px;
    }
</style>
<script setup>
    import { ref, onBeforeMount } from 'vue'
    import PopupDialog from '@/Components/ui-components/PopupDialog.vue'
    import PrimaryButton from '@/Components/PrimaryButton.vue'
    import SecondaryButton from '@/Components/SecondaryButton.vue'

    const isHidensfw = ref(false)
    const notToClose = () => {
        isHidensfw.value = true
    }

    onBeforeMount(() => {
        setTimeout(() => {
            isHidensfw.value = true
            let doc_cookie = document.cookie
            if (doc_cookie.includes('MOCK_nsfw')) {
                isHidensfw.value = false
            } else {
                isHidensfw.value = true
            }
        }, 500)
    })

    function setnsfwcookie() {
        document.cookie = `MOCK_nsfw='c29tZSBlbmNvZGVkIHZhbHVlcw==';secure: true; expires=${new Date(
            new Date().getTime() + 1000 * 60 * 60 * 24 * 365,
        ).toGMTString()}; path=/;`

        const currentVideoElement = document.querySelector('.current-video video')

        setTimeout(() => {
            if (currentVideoElement) {
                console.log('playing video')
                currentVideoElement.play()
            }

            isHidensfw.value = false
        }, 500)
    }

    const declinensfw = () => {
        window.location.href = 'https://en.wikipedia.org/wiki/Pussy'
    }
</script>
