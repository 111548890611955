// useUserInteraction.js
import { onMounted, onUnmounted, ref } from 'vue'

export function useUserInteraction() {
    const hasInteracted = ref(false)

    const setUserInteracted = () => {
        if (!hasInteracted.value) {
            hasInteracted.value = true
            // Cleanup listeners once the user has interacted to avoid unnecessary performance overhead
            removeEventListeners()
        }
    }

    const addEventListeners = () => {
        window.addEventListener('click', setUserInteracted)
        window.addEventListener('keydown', setUserInteracted)
        window.addEventListener('touchend', setUserInteracted)
        // Add more event listeners as needed, e.g., touch events for mobile devices
    }

    const removeEventListeners = () => {
        window.removeEventListener('click', setUserInteracted)
        window.removeEventListener('keydown', setUserInteracted)
        window.removeEventListener('touchend', setUserInteracted)
        // Remove other event listeners as well
    }

    onMounted(() => {
        addEventListeners()
    })

    onUnmounted(() => {
        removeEventListeners()
    })

    return {
        hasInteracted,
    }
}
