<script setup>
    import { computed, onMounted, onUnmounted, watch, ref } from 'vue'
    import { Link, usePage } from '@inertiajs/vue3'

    const props = defineProps({
        conversion_user_id: {
            type: Number,
            default: null,
        },
        float_user_id: {
            type: Number,
            default: null,
        },
        show: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: '2xl',
        },
        closeable: {
            type: Boolean,
            default: true,
        },
        isBlured: {
            type: Boolean,
            default: false,
        },
        isCreator: {
            type: Boolean,
            default: false,
        },
        hideScroll: {
            type: Boolean,
            default: true,
        },
    })
    const showMarketingFlyout = ref(false)
    const closeMarketingFlyout = () => {
        showMarketingFlyout.value = false
    }
    const emit = defineEmits(['close'])

    watch(
        () => props.show,
        () => {
            if (props.hideScroll) {
                if (props.show) {
                    document.body.style.overflow = 'hidden'
                } else {
                    document.body.style.overflow = null
                }
            }
        },
    )

    const close = () => {
        emit('close')
    }

    const closeOnEscape = e => {
        if (e.key === 'Escape' && props.show) {
            close()
        }
    }

    onMounted(() => document.addEventListener('keydown', closeOnEscape))

    onUnmounted(() => {
        document.removeEventListener('keydown', closeOnEscape)
        document.body.style.overflow = null
    })

    const maxWidthClass = computed(() => {
        return {
            sm: 'sm:max-w-sm',
            md: 'sm:max-w-md',
            lg: 'sm:max-w-lg',
            xl: 'sm:max-w-xl',
            '2xl': 'sm:max-w-2xl',
        }[props.maxWidth]
    })
    const closeModal = () => {
        emit('close')
    }

    const ad = computed(() => {
        return usePage().props?.user_ad ?? {}
    })
</script>

<template>
    <teleport to="body">
        <transition leave-active-class="duration-200">
            <div
                id="staticModal"
                data-modal-backdrop="static"
                tabindex="-1"
                v-show="show"
                class="fixed inset-0 z-50 mx-auto max-w-[1522px] overflow-y-auto px-4 py-6 sm:px-0"
                :class="{ 'has-blur': isBlured }">
                <transition
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <div
                        v-show="show"
                        class="fixed inset-0 transform transition-all"
                        @click="closeModal">
                        <div class="absolute inset-0 bg-black opacity-75" />
                    </div>
                </transition>

                <transition
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div
                        v-show="show"
                        class="absolute right-0 top-0 h-full w-[80%] transform overflow-hidden bg-black px-[20px] shadow-xl transition-all dark:bg-black sm:w-[476px] sm:px-[58px]"
                        :class="maxWidthClass">
                        <div
                            @click="closeModal"
                            class="absolute right-[15px] top-[25px] h-[25px] w-[25px] cursor-pointer text-white sm:right-[56px]">
                            <font-awesome-icon
                                class="h-[25px] w-[25px] text-white"
                                :icon="['fas', 'xmark']" />
                        </div>
                        <div class="ad-goes-here pb-5 pt-[100px]" v-html="ad.desktop_html"></div>
                        <div class="flex-grow pt-[56px]">
                            <div class="flex flex-col items-center space-y-4">
                                <!-- Logged Out-->

                                <Link
                                    data-drawer-hide="right-navigation"
                                    @click="closeModal"
                                    class="mx-auto block w-full max-w-[360px] rounded-md border border-mintgreen text-center text-sm font-medium leading-[49px] tracking-wide hover:bg-mintgreen dark:text-white dark:hover:text-black sm:text-xl sm:leading-[57px]"
                                    :href="
                                        route('access', {
                                            type: 'join',
                                            conversion_user_id: conversion_user_id,
                                            float_user_id: float_user_id,
                                        })
                                    ">
                                    Join For Free or Login
                                </Link>

                                <Link
                                    v-show="isCreator"
                                    @click="closeModal"
                                    data-drawer-hide="right-navigation"
                                    class="mx-auto mb-10 mt-5 block w-full max-w-[360px] rounded-md bg-mintgreen text-center text-sm font-medium leading-[49px] tracking-wide text-black hover:bg-hovermintgreen sm:text-xl sm:leading-[57px]"
                                    :href="route('creator.signup')">
                                    Become a Creator
                                </Link>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </teleport>
</template>
<style>
    .has-blur::after {
        background-color: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(12px) brightness(110%);
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: -1;
    }
</style>
