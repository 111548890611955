<template>
    <Modal :show="show" @close="closeModal" :maxWidth="maxWidth" :hideScroll="hideScroll">
        <div :class="mainClass">
            <!-- Popup header -->
            <div
                class="flex items-start justify-between rounded-t pb-0"
                :class="title !== undefined ? '' : 'hidden'">
                <h3
                    v-if="title !== undefined"
                    class="text-sm font-medium uppercase text-gray-900 dark:text-white">
                    {{ title }}
                </h3>
                <font-awesome-icon
                    v-show="closeable"
                    @click="closeModal"
                    data-modal-hide="staticModal"
                    icon="fa-solid fa-xmark"
                    class="ml-auto inline-flex h-[20px] w-[20px] items-center justify-center rounded-lg bg-transparent p-[4px] text-sm text-graytext hover:text-white sm:hover:cursor-pointer" />
            </div>
            <!-- Popup body -->
            <div class="flex justify-between">
                <slot name="bodyContainer"></slot>
                <!-- Popup footer -->
                <div v-if="hasButtons" class="flex items-center justify-center space-x-2 rounded-b">
                    <AppButton
                        data-modal-hide="staticModal"
                        @click="confirmButton"
                        :text="confirmButtonText"
                        ui="primary" />
                    <AppButton
                        data-modal-hide="staticModal"
                        @click="cancelButton"
                        :text="cancelButtonText"
                        ui="secondary" />
                </div>
            </div>
            <slot name="footer"></slot>
        </div>
    </Modal>
</template>
<script setup>
    import Modal from '@/Components/Modal.vue'
    import AppButton from '@/Components/ui-components/AppButton.vue'

    defineProps({
        show: {
            type: Boolean,
            default: true,
        },
        maxWidth: {
            type: String,
            default: '2xl',
        },
        closeable: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: undefined,
        },
        bodyContainer: {
            type: String,
            default: undefined,
        },
        confirmButtonText: {
            type: String,
            default: 'Confirm',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        hasButtons: {
            type: Boolean,
            default: true,
        },
        hideScroll: {
            type: Boolean,
            default: true,
        },
        mainClass: {
            type: String,
            default: 'relative rounded-lg bg-white dark:bg-black',
        },
    })

    const emit = defineEmits(['closeModal', 'cancelButton', 'confirmButton'])

    const closeModal = () => {
        emit('closeModal')
    }

    const confirmButton = () => {
        emit('confirmButton')
    }

    const cancelButton = () => {
        emit('cancelButton')
    }
</script>
