<script setup>
    import { Link } from '@inertiajs/vue3'
    import { ref } from 'vue'

    const currentYear = ref(new Date().getFullYear())
</script>
<template>
    <div>
        <a
            href="https://www.instagram.com/extra.naughty/"
            target="_blank"
            class="mr-[16px] inline-block">
            <font-awesome-icon
                :icon="['fab', 'instagram']"
                class="h-[25px] w-[25px] cursor-pointer text-lightgray hover:text-instagramRose dark:text-lightgray dark:hover:text-instagramRose" />
        </a>
        <a href="https://twitter.com/Extra_Naughty" target="_blank" class="inline-block">
            <font-awesome-icon
                :icon="['fab', 'x-twitter']"
                class="h-[25px] w-[25px] cursor-pointer text-lightgray hover:text-twitterblue dark:text-lightgray dark:hover:text-twitterblue" />
        </a>
    </div>
    <footer
        class="w-full pb-[40px] pt-[20px] text-left dark:bg-black dark:text-lightgray sm:pb-[70px] sm:pt-[20px]">
        <div class="space-x-4">
            <Link
                data-drawer-hide="right-navigation"
                :href="route('faq')"
                class="inline text-xs hover:underline"
                >FAQ</Link
            >
            <Link
                data-drawer-hide="right-navigation"
                :href="route('support')"
                class="inline text-xs hover:underline"
                >Support</Link
            >
            <Link
                data-drawer-hide="right-navigation"
                :href="route('tos')"
                class="inline text-xs hover:underline"
                >Terms of Service</Link
            >
            <Link
                data-drawer-hide="right-navigation"
                :href="route('privacy')"
                class="text-xs hover:underline"
                >Privacy Policy</Link
            >
        </div>

        <div class="mt-2 space-x-4">
            <a
                data-drawer-hide="right-navigation"
                href="https://www.epoch.com"
                class="inline text-xs hover:underline"
                target="_blank"
                >Epoch.com</a
            >
            <a
                data-drawer-hide="right-navigation"
                href="https://segpaycs.com/"
                class="inline text-xs hover:underline"
                target="_blank"
                >SegPay.com</a
            >
            <a
                data-drawer-hide="right-navigation"
                :href="route('dmca')"
                class="inline text-xs hover:underline"
                >DMCA</a
            >
            <a
                data-drawer-hide="right-navigation"
                :href="route('sitemap')"
                class="inline text-xs hover:underline"
                >Sitemap</a
            >
        </div>
        <div class="mt-2 space-x-4">
            <a
                data-drawer-hide="right-navigation"
                :href="route('content-removal')"
                class="inline text-xs hover:underline"
                >Content Removal</a
            >
            <a
                data-drawer-hide="right-navigation"
                :href="route('prohibitedContent')"
                class="inline text-xs hover:underline"
                >Prohibited Content</a
            >
        </div>

        <div class="mt-2">
            <Link
                data-drawer-hide="right-navigation"
                :href="route('compliance')"
                class="inline text-xs hover:underline"
                >18 USC 2257 Record-Keeping Requirements Compliance Statement</Link
            >
        </div>
        <div class="mt-2">
            <p class="block text-xs">
                <Link class="inline text-xs hover:underline" :href="route('complianceMastercard')">
                    Compliance with MasterCard Standards
                </Link>
            </p>
        </div>
        <div class="mt-2">
            <p class="block text-xs">©{{ currentYear }} Official Fans, Inc.</p>
        </div>
    </footer>
</template>
