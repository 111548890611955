<template>
    <transition name="fade">
        <div v-if="showLogo">
            <img
                v-if="!isLoggedIn"
                width="160"
                height="32"
                aria-label="EXN Home"
                alt="EXN Home"
                class="m-0 hidden h-auto w-[160px] dark:flex xxs:w-[210px] sm:w-[210px] md:w-[320px]"
                src="./../../../public/images/ExtraNaughtyLogo2.png" />
            <img
                v-else
                width="160"
                height="32"
                aria-label="EXN Home"
                alt="EXN Home"
                class="m-0 hidden h-auto w-[160px] dark:flex xxs:w-[210px] sm:w-[210px] md:w-[320px]"
                src="./../../../public/images/ExtraNaughtyLogoBETA.png" />
        </div>
    </transition>
</template>

<script setup>
    import { ref, onMounted, onUnmounted, computed } from 'vue'
    import { usePage } from '@inertiajs/vue3'

    const { props } = usePage()

    const isLoggedIn = ref(props?.auth && props?.auth.user && props?.auth.user.name !== null)
    const scrollY = ref(0)
    const showLogo = computed(
        () => (scrollY.value <= 50 && window.innerWidth <= 640) || window.innerWidth > 640,
    )

    const updateScroll = () => {
        scrollY.value = window.scrollY
    }

    onMounted(() => {
        // updateScroll() // Initialize the scroll value on mount
        // window.addEventListener('scroll', updateScroll)
        // window.addEventListener('resize', updateScroll)
    })

    onUnmounted(() => {
        //   window.removeEventListener('scroll', updateScroll)
        //   window.removeEventListener('resize', updateScroll)
    })
</script>

<style scoped>
    /* Fade transition */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
        opacity: 0;
    }
</style>
