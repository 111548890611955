<script setup>
    import { ref, onMounted, onBeforeMount, onUpdated } from 'vue'
    import { Link, usePage, useForm } from '@inertiajs/vue3'
    import Modal from '@/Components/Modal.vue'

    const { props } = usePage()

    const showModal = ref(false)

    const emit = defineEmits(['close'])

    const closeModal = () => {
        emit('close')
    }
</script>

<template>
    <Modal :show="showModal" :closeable="true">
        <div class="relative rounded-lg border border-darkgray bg-[#111111] shadow">
            <!-- Modal header -->
            <button @click="closeModal" class="absolute right-[5px] top-[5px] text-white">
                <font-awesome-icon
                    class="h-[25px] w-[25px] text-iconWhite hover:text-iconhover sm:h-[35px] sm:w-[35px]"
                    :icon="['fas', 'xmark']" />
            </button>
            <div class="pt-[20px] text-center">
                <font-awesome-icon
                    class="h-[125px] w-[125px] text-iconWhite hover:text-iconhover sm:h-[135px] sm:w-[135px]"
                    :icon="['far', 'credit-card']" />

                <div class="flex items-center justify-center rounded-t p-4 text-center text-white">
                    Add Card to file, Get $20 Live Credit!
                </div>
                <Link
                    @click="closeModal"
                    class="mx-auto mb-10 mt-5 block w-full max-w-[200px] rounded-md border-0 bg-mintgreen text-center text-sm font-medium leading-[49px] tracking-wide text-black hover:bg-hovermintgreen sm:text-xl sm:leading-[57px]"
                    :href="route('profile.paymentMethod', { backref: props.backref })">
                    Add Card
                </Link>
            </div>
        </div>
    </Modal>
</template>
