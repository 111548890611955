<script setup>
    import { ref, computed, onBeforeUnmount, nextTick, provide, onBeforeMount } from 'vue'
    import ApplicationLogo from '@/Components/ApplicationLogo.vue'
    import Dropdown from '@/Components/Dropdown.vue'
    import DropdownLink from '@/Components/DropdownLink.vue'
    import MobileFooter from '@/Components/navigation/MobileFooter.vue'
    import RightMenu from '@/Components/navigation/RightMenu.vue'
    import ResponsiveNavLink from '@/Components/ResponsiveNavLink.vue'
    import { Link, router } from '@inertiajs/vue3'
    import LoggedOutMenu from '@/Components/navigation/LoggedOutMenu.vue'
    import LoggedInMenu from '@/Components/navigation/LoggedInMenu.vue'
    import LoggedInMenuCreator from '@/Components/navigation/LoggedInMenuCreator.vue'
    import { usePage } from '@inertiajs/vue3'
    import { onMounted, onUpdated, onUnmounted } from 'vue'
    import { defineStore } from 'pinia'
    import NsfwDialog from '@/Components/NsfwDialog.vue'
    import MarketingFlyout from '@/Components/scene/MarketingFly.vue'
    import CreatorFlyout from '@/Components/main-components/CreatorFly.vue'
    import { useTagsStore } from '@/store/tagsStore'
    import { useUserInteraction } from '@/composables/useUserInteraction.js'
    import posthog from 'posthog-js' // Adjust the path as necessary

    const { hasInteracted } = useUserInteraction()

    // Make `hasInteracted` available to all child components
    provide('hasInteracted', hasInteracted)

    const pathName = window.location.pathname
    const user = usePage()?.props?.auth?.user
    const isOverriding = usePage()?.props?.overriding_user

    const overridingUser = ref(
        isOverriding === user?.name && user?.name !== null ? isOverriding : null,
    )

    const { props } = usePage()

    const showCreatorFlyout = ref(false)

    const openCreatorFlyout = () => {
        showCreatorFlyout.value = true
    }
    const showMarketingFlyout = ref(false)
    const openMarketingFlyout = () => {
        showMarketingFlyout.value = true
    }

    const closeMarketingFlyout = () => {
        showMarketingFlyout.value = false
    }

    defineProps({
        canLogin: {
            type: Boolean,
        },
        canRegister: {
            type: Boolean,
        },
        creator: {
            type: Object,
            default: {},
        },
        isCreator: {
            type: Boolean,
            default: false,
        },
    })

    // Computed property to check if the user is logged in
    const isLoggedIn = ref(props?.auth && props?.auth.user && props?.auth.user.name !== null)

    const showingNavigationDropdown = ref(false)

    const handleLogout = async () => {
        // Perform logout action here (e.g., making an API request, clearing local storage, etc.)
        console.log('logged out')
        // Update isLoggedIn ref after successful logout
        isLoggedIn.value = false

        // Redirect to the desired route after logout (e.g., home page)
        // Replace `route('home')` with the appropriate route path
    }

    // initialize components based on data attribute selectors
    onMounted(() => {
        document.addEventListener('click', handleClick)

        // Prevent the pageload from triggering false scroll events
        setTimeout(() => {
            document.addEventListener('scroll', handleScroll)
        }, 3000)
    })

    const handleClick = event => {
        if (event.target.matches('div[drawer-backdrop]')) {
            event.target.remove()
        }

        if (isReferredURL()) {
            reportEngagement()
        }
    }

    const handleScroll = event => {
        if (isReferredURL()) {
            reportEngagement()
        }
    }

    const isReferredURL = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const utmCampaign = urlParams.get('utm_campaign')

        if (utmCampaign === 'na_ma_underpop') {
            return true
        }

        return false
    }
    const reportEngagement = () => {
        if (getCookie('engagement_reported') !== 'true') {
            fathom.trackEvent('Popunder User Engagement')
            setCookie('engagement_reported', 'true')
        }
    }

    onMounted(() => {
        isPWA()
        document.body.addEventListener('wheel', preventHorizontalScroll, { passive: true })
        window.addEventListener('popstate', removeModalBackdrop)
    })
    const removeModalBackdrop = () => {
        const modalBackdrop = document.querySelector('[modal-backdrop]')

        if (modalBackdrop) {
            modalBackdrop.remove()
            document.body.classList.remove('overflow-hidden')
        }
    }

    onUnmounted(() => {
        document.body.removeEventListener('wheel', preventHorizontalScroll, { passive: true })
        window.removeEventListener('popstate', removeModalBackdrop)
    })

    const isFireFox = ref(false)

    // Check if the user agent indicates mozilla firefox.
    const mozillaPattern = /mozilla\/[.0-9]*/
    isFireFox.value = mozillaPattern.test(navigator.userAgent)

    function preventHorizontalScroll(event) {
        if (!mozillaPattern && event.deltaX !== 0) {
            event.preventDefault()
        }
    }

    const pwaDetected = ref(false)

    if (isPWA()) {
        pwaDetected.value = true
        console.log('StandAlone App detected.')
        document.body.classList.add('pwa-app')
    }

    function isPWA() {
        return (
            window.matchMedia('(display-mode: standalone)').matches ||
            window.navigator.standalone ||
            document.referrer.includes('android-app://')
        )
    }
    const isCreator = computed(() => !!props?.creator?.isCreator)

    const handleDoubleClick = event => {
        event.preventDefault()
        // You can add additional logic here if needed
    }
    const marketingFlyoutProps = computed(() => {
        if (isLoggedIn.value) {
            return {
                isCreator: props?.creator?.isCreator,
            }
        }
        return {}
    })
    const nsfwPopup = ref(import.meta.env.VITE_NSFW_POPUP === 'true')

    const mainDivRef = ref(null)
    const tagsStore = useTagsStore()

    function handleResetAndNavigate() {
        //console.log('reset and navigate')
        tagsStore.resetStore() // Reset the store
        router.visit(route('explore.index'))
    }

    function getCookie(name) {
        const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
        return cookieValue ? cookieValue.pop() : null
    }

    function setCookie(name, value) {
        document.cookie = name + '=' + value + ';path=/'
    }

    const goMessagePage = () => {
        router.visit(route('message'))
    }
    const pathNameProp = ref(window.location.pathname)

    onMounted(() => {
        pathNameProp.value
    })

    const isGooglebot = ref(false)

    const checkUserAgent = () => {
        const userAgent = navigator.userAgent
        isGooglebot.value = userAgent.includes('Googlebot')
    }

    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has('guest_sign_up')) {
        fathom.trackEvent('guest_sign_up')
        console.log('guest_sign_up')
    }

    onMounted(() => {
        checkUserAgent()
    })
</script>

<template>
    <NsfwDialog v-if="nsfwPopup && !isGooglebot"></NsfwDialog>
    <div @contextmenu="$disableRightClick" @dblclick="handleDoubleClick" ref="mainDivRef">
        <div id="masterlayout" class="min-h-screen bg-gray-100 pb-[70px] dark:bg-black">
            <nav
                id="desktop-header"
                class="fixed bottom-auto top-0 z-[10] w-full bg-opacity-[80%] sm:block sm:pl-0 sm:pt-[0px] lg:pr-[0px] xl:pr-[0px] 2xl:pl-[0px]">
                <!-- Primary Navigation Menu -->
                <div
                    class="header-contain lg:max-w-[ 100%] mx-auto w-full max-w-[100%] px-[10px] sm:px-[10px] md:px-[10px] lg:pl-[40px] lg:pr-[0px] xl:max-w-[100%] xl:pl-[50px] xl:pr-[58px] 2xl:max-w-[1522px] 2xl:pl-[58px] 2xl:pr-[54px]">
                    <div class="nav-links flex h-[55px] justify-between sm:h-[65px]">
                        <div class="logo-contain flex">
                            <!-- Logo -->
                            <div
                                class="flex shrink-0"
                                :class="[overridingUser ? 'h-[32px] flex-col' : 'items-center']">
                                <Link :href="route('home')">
                                    <ApplicationLogo />
                                </Link>

                                <div
                                    v-if="overridingUser"
                                    class="alert mt-4 flex h-[24px] items-center rounded bg-yellow-500 px-4 py-2 text-white">
                                    Currently overriding {{ overridingUser }}
                                </div>
                            </div>

                            <!-- Navigation Links -->
                        </div>

                        <div class="min-w-10 hidden min-h-[65px] sm:flex sm:items-center">
                            <RightMenu :isLoggedIn="isLoggedIn" :pathNameProp="pathNameProp" />
                        </div>
                        <div class="flex items-center sm:hidden">
                            <div
                                v-if="!isLoggedIn"
                                @click="openMarketingFlyout"
                                class="w-auto cursor-pointer rounded-[10px] text-center text-[1rem] font-medium leading-[40px] tracking-[0.03rem] text-mintgreen hover:text-hovermintgreen sm:hidden">
                                Join Free
                            </div>

                            <!-- Hamburger -->
                            <div class="-mr-2 flex items-center pl-[1px] sm:hidden">
                                <button
                                    data-drawer-target="right-navigation"
                                    data-drawer-show="right-navigation"
                                    data-drawer-backdrop="true"
                                    aria-controls="right-navigation"
                                    data-drawer-placement="right"
                                    alt="Open Navigation Menu"
                                    aria-label="Open Navigation Menu"
                                    class="mobile-right-navigation inline-flex items-center justify-center rounded-md p-3 text-2xl text-gray-300 transition duration-150 ease-in-out hover:text-mintgreen focus:outline-none">
                                    <font-awesome-icon
                                        class="block h-[1.85rem] w-[1.85rem] cursor-pointer text-white hover:text-iconhover dark:text-white dark:hover:text-iconhover md:hidden"
                                        :icon="['fas', 'ellipsis-vertical']" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Responsive Navigation Menu -->
                <div
                    :class="{
                        block: showingNavigationDropdown,
                        hidden: !showingNavigationDropdown,
                    }"
                    class="sm:hidden">
                    <div class="space-y-1 border-gray-200 pt-1">
                        <ResponsiveNavLink :href="route('home')" :active="route().current('home')">
                            Home
                        </ResponsiveNavLink>
                    </div>

                    <!-- Responsive Settings Options -->
                    <div class="pb-1" v-if="isLoggedIn">
                        <div class="space-y-1">
                            <ResponsiveNavLink :href="route('profile.edit')">
                                Profile
                            </ResponsiveNavLink>
                            <ResponsiveNavLink :href="route('notifications')">
                                Notifications
                            </ResponsiveNavLink>
                            <a :href="route('chatify', { origin: pathName })">Messages</a>
                            <ResponsiveNavLink :href="route('home')"> Coins</ResponsiveNavLink>
                            <ResponsiveNavLink
                                :href="route('logout')"
                                method="post"
                                as="button"
                                @click="handleLogout">
                                Log Out
                            </ResponsiveNavLink>
                        </div>
                    </div>
                    <div class="pb-1" v-if="!isLoggedIn">
                        <div class="space-y-1">
                            <ResponsiveNavLink :href="route('login')"> Login</ResponsiveNavLink>
                            <ResponsiveNavLink :href="route('register')">
                                Register
                            </ResponsiveNavLink>
                        </div>
                    </div>
                </div>
            </nav>

            <!-- Page Heading -->
            <header class="bg-white shadow" v-if="$slots.header">
                <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <slot name="header" />
                </div>
            </header>
            <!-- Page Content -->
            <main
                ref="mainDivRef"
                id="main"
                class="relative mx-auto mb-[70px] mt-[55px] max-w-[1522px] px-0 sm:mt-[65px] xl:px-0">
                <LoggedOutMenu
                    :pathNameProp="pathNameProp"
                    @resetAndNavigate="handleResetAndNavigate"
                    @showFlyout="openMarketingFlyout"
                    @goMessagePage="goMessagePage"
                    v-if="!isLoggedIn" />
                <LoggedInMenuCreator
                    @logout="handleLogout"
                    :isCreator="props?.creator?.isCreator"
                    v-else-if="props?.creator?.isCreator && isLoggedIn" />
                <LoggedInMenu
                    v-if="isLoggedIn"
                    @showCreatorFlyout="openCreatorFlyout"
                    @logout="handleLogout"
                    :is-logged-in="isLoggedIn"
                    v-on:logout="isLoggedIn = false" />
                <slot />
            </main>

            <MobileFooter
                @showFlyout="openMarketingFlyout"
                @goMessagePage="goMessagePage"
                :is-logged-in="isLoggedIn" />
        </div>
    </div>
    <MarketingFlyout
        :show="showMarketingFlyout"
        @close="showMarketingFlyout = false"
        v-bind="marketingFlyoutProps"
        :hideScroll="false" />
    <CreatorFlyout :show="showCreatorFlyout" @close="showCreatorFlyout = false" />
</template>
<style>
    body {
        background-color: black;
    }

    div.bg-gray-900.bg-opacity-50 {
        background-color: rgba(0, 0, 0, 0.5);
    }

    div[drawer-backdrop] {
        display: none;
    }

    div[drawer-backdrop]:nth-last-child(1) {
        display: block;
    }
</style>
