import { defineStore } from 'pinia'

export const useTagsStore = defineStore('tags', {
    state: () => ({
        // Initialize state with saved data or default values
        tagsByContentCarousel: JSON.parse(sessionStorage.getItem('tagsByContentCarousel')) || [],
        page: JSON.parse(sessionStorage.getItem('tagsPage')) || -1,
        lastScrollPosition: JSON.parse(sessionStorage.getItem('lastScrollPosition')) || 0,
    }),
    actions: {
        addTag(tag) {
            this.tagsByContentCarousel.push(tag)
            // Save updated state to sessionStorage
            sessionStorage.setItem(
                'tagsByContentCarousel',
                JSON.stringify(this.tagsByContentCarousel),
            )
        },
        incrementPage() {
            this.page += 1
            sessionStorage.setItem('tagsPage', JSON.stringify(this.page))
        },
        setLastScrollPosition(position) {
            this.lastScrollPosition = position
            sessionStorage.setItem('lastScrollPosition', JSON.stringify(this.lastScrollPosition))
        },
        resetStore() {
            this.lastScrollPosition = 0
        },
    },
})
