<template>
    <Link
        v-if="isLink"
        :href="href"
        class="text-md flex items-center justify-center py-[6px] text-center font-medium"
        :class="`${modifier} ${extra} ${height} ${hasRounded}`">
        <component v-show="icon != undefined" is="icon" class="my-auto mr-1 flex h-[14px] w-[14px]">
            <font-awesome-icon :icon="icon != undefined ? icon : 'fa-regular fa-check'" />
        </component>
        <span v-if="text">{{ text }}</span>
    </Link>
    <a
        v-else-if="isExternalLink"
        :href="href"
        class="text-md flex items-center justify-center py-[6px] text-center font-medium"
        :class="`${modifier} ${extra} ${height} ${hasRounded}`">
        <component v-show="icon != undefined" is="icon" class="my-auto mr-1 flex h-[14px] w-[14px]">
            <font-awesome-icon :icon="icon != undefined ? icon : 'fa-regular fa-check'" />
        </component>
        <span v-if="text">{{ text }}</span>
    </a>

    <button
        v-else
        :disabled="isDisabled"
        class="text-md flex items-center justify-center text-center font-medium"
        :class="
            isDisabled
                ? `${height} ${disabledModifier} ${hasRounded}`
                : `${modifier} ${extra} ${height} ${hasRounded}`
        ">
        <component v-show="icon != undefined" is="icon">
            <font-awesome-icon
                :icon="icon != undefined ? icon : 'fa-regular fa-check'"
                :class="iconClass ? iconClass : 'my-auto flex h-[21px] w-[21px] text-white'" />
        </component>
        <span v-if="text" class="mx-[10px] text-[inherit]" :class="textClass">{{ text }}</span>
    </button>
</template>
<script setup>
    import { computed, ref } from 'vue'
    import { Link } from '@inertiajs/vue3'

    const props = defineProps({
        icon: {
            type: String,
            default: undefined,
        },
        iconClass: {
            type: String,
            default: undefined,
        },
        text: {
            type: String,
            default: '',
        },

        textClass: {
            type: String,
        },
        ui: {
            type: String,
            default: 'primary',
        },
        extra: {
            type: String,
            default: '',
        },
        isLink: {
            type: Boolean,
            default: false,
        },
        isExternalLink: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
        },
        height: {
            type: String,
            default: 'min-h-[37px]',
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        disabledModifier: {
            type: String,
            default: 'bg-gray-300 px-[10px] text-gray-400',
        },
        hasRounded: {
            type: [Boolean, String],
            default: 'rounded-md',
        },
    })

    const modifier = computed(() => {
        return {
            primary: 'bg-mintgreen text-black hover:bg-hovermintgreen dark:text-gray-900 px-[10px]',
            unlock: 'bg-mintgreen uppercase text-black hover:bg-hovermintgreen dark:text-gray-900 px-0 tracking-[0.36px] lg:tracking-[0.42px]',
            primaryLite: 'bg-mintgreen text-black hover:bg-mintgreen dark:text-gray-900 px-[10px]',
            secondary:
                'bg-followgray text-white text-sm sm:hover:bg-mintgreen sm:hover:text-[#0a0a0a] tracking-[0.4px] px-[10px]',
            secondaryLite:
                'bg-[#717171] text-white text-sm sm:hover:bg-mintgreen sm:hover:text-[#0a0a0a] tracking-[0.4px] px-[10px]',
            secondaryDark:
                'bg-graycontrol text-white text-sm sm:hover:bg-white sm:hover:text-black tracking-[.4px] px-[10px]',
            tertiary:
                'border border-mintgreen text-white px-[10px] sm:hover:bg-mintgreen sm:hover:text-black',
            link: 'sm:max-w-lg',
            danger: 'bg-[#FF0000] text-white hover:bg-[#d42307] px-[10px]',
            warning: 'bg-[#FF9600] text-white hover:bg-[#b2700f] px-[10px]',
            mintgreen: 'bg-mintgreen text-white hover:bg-hovermintgreen px-[10px]',
        }[props.ui]
    })
</script>
