<script setup>
    import { computed, onMounted, onUnmounted, watch } from 'vue'

    const props = defineProps({
        show: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: '2xl',
        },
        closeable: {
            type: Boolean,
            default: true,
        },
        isBlured: {
            type: Boolean,
            default: false,
        },
        className: {
            type: String,
            default: '',
        },
        hideScroll: {
            type: Boolean,
            default: true,
        },
    })

    const emit = defineEmits(['close'])

    watch(
        () => props.show,
        () => {
            if (props.hideScroll) {
                if (props.show) {
                    document.body.style.overflow = 'hidden'
                } else {
                    document.body.style.overflow = null
                }
            }
        },
    )

    const close = () => {
        if (props.closeable) {
            emit('close')
        }
    }

    const closeOnEscape = e => {
        if (e.key === 'Escape' && props.show) {
            close()
        }
    }

    onMounted(() => document.addEventListener('keydown', closeOnEscape))

    onUnmounted(() => {
        document.removeEventListener('keydown', closeOnEscape)
        document.body.style.overflow = null
    })

    const maxWidthClass = computed(() => {
        return {
            sm: 'sm:max-w-sm',
            md: 'sm:max-w-md',
            lg: 'sm:max-w-lg',
            xl: 'sm:max-w-xl',
            '2xl': 'sm:max-w-2xl',
        }[props.maxWidth]
    })
</script>

<template>
    <teleport to="body">
        <transition leave-active-class="duration-200">
            <div
                id="staticModal"
                data-modal-backdrop="static"
                tabindex="-1"
                aria-hidden="true"
                v-show="show"
                class="fixed inset-0 z-50 overflow-y-auto px-4 py-6 sm:px-0"
                :class="{ 'has-blur': isBlured }">
                <transition
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <div
                        v-show="show"
                        class="fixed inset-0 transform transition-all"
                        @click="close">
                        <div class="absolute inset-0 bg-black opacity-75" />
                    </div>
                </transition>

                <transition
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0 sm:scale-95"
                    enter-to-class="opacity-100 sm:scale-100"
                    appearFromClass="opacity-100 sm:scale-100"
                    appearActiveClass="opacity-100 sm:scale-100"
                    appearToClass="opacity-0 sm:scale-95"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100 sm:scale-100"
                    leave-to-class="opacity-0 sm:scale-95">
                    <div
                        v-show="show"
                        class="relative top-[50%] translate-y-[-50%] overflow-hidden rounded-[6px] bg-white shadow-xl transition-all dark:bg-black sm:mx-auto sm:w-full"
                        :class="`${maxWidthClass} ${className}`">
                        <slot v-if="show" />
                    </div>
                </transition>
            </div>
        </transition>
    </teleport>
</template>
<style>
    .has-blur::after {
        background-color: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(12px) brightness(110%);
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: -1;
    }
</style>
