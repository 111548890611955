<script setup>
    import { ref, watch, onMounted } from 'vue'

    const isDarkMode = ref(true) // Default to light mode

    watch(isDarkMode, newValue => {
        document.documentElement.classList[newValue ? 'add' : 'remove']('dark')
        localStorage.setItem('color-theme', newValue ? 'dark' : 'light')
    })

    const toggleTheme = () => {
        // isDarkMode is automatically toggled by v-model
    }

    onMounted(() => {
        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
        const storedTheme = localStorage.getItem('color-theme')
        if (storedTheme) {
            isDarkMode.value = storedTheme === 'dark'
        } else if (prefersDarkMode) {
            isDarkMode.value = true
        }

        if (isDarkMode.value) {
            document.documentElement.classList.add('dark')
        }
    })
</script>

<template>
    <div class="theme-toggle-container hidden">
        <label class="theme-toggle-slider flex cursor-pointer items-center">
            <span
                class="menu-link relative w-auto border-l-4 border-transparent py-[.5rem] pr-[10px] text-left text-[16px] font-medium text-gray-600 transition duration-150 ease-in-out focus:outline-none dark:text-white dark:hover:text-mintgreen"
                >Dark Mode</span
            >

            <input type="checkbox" v-model="isDarkMode" @change="toggleTheme" />
            <span class="slider round"></span>
        </label>
    </div>
</template>

<style>
    .theme-toggle-slider {
        display: flex;
        align-items: center; /* Center items vertically */
    }

    .slider {
        display: inline-block; /* Use inline-block for the slider */
        width: 22px;
        height: 15px;
        position: relative;
        cursor: pointer;
        background-color: #7a7a7a;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 11px;
        width: 10px;
        left: 2px;
        bottom: 2px;
        background-color: black;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #7a7a7a;
    }

    input:checked + .slider:before {
        transform: translateX(8px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
    .theme-toggle-container [type='checkbox'] {
        display: none;
    }
</style>
