<script setup>
    import { computed } from 'vue'
    import { Link } from '@inertiajs/vue3'

    const props = defineProps({
        href: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
        },
    })

    const classes = computed(() =>
        props.active
            ? 'block w-full pr-4 border-l-4 border-indigo-400 text-left text-base font-medium text-indigo-700 bg-indigo-50 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out'
            : 'block w-full pr-4 border-l-4 border-transparent text-left text-base font-medium text-gray-600 dark:hover:text-mintgreen focus:outline-none  transition duration-150 ease-in-out dark:text-white',
    )
</script>

<template>
    <Link :href="href" :class="classes" class="menu-link">
        <slot />
    </Link>
</template>
<style>
    .menu-link:hover svg path {
        fill: #65e8a4;
    }
</style>
