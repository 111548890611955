<!-- Page Footer -->
<template>
    <footer class="mobileFooter">
        <slot name="mobileFooter" />

        <div
            id="mobileFooter"
            class="fixed bottom-[-1px] left-0 z-10 flex h-[55px] w-full justify-between bg-white bg-opacity-80 dark:bg-black dark:bg-opacity-80 sm:hidden"
            :class="[pwaDetected ? 'bottom-[30px]' : 'bottom-[-1px]', blackbarAdded]">
            <div class="mx-auto flex w-full max-w-[640px]">
                <Link
                    :href="route('home')"
                    aria-label="Home"
                    alt="Home"
                    class="flex w-[22%] max-w-[90px] items-center pl-[10px]">
                    <img
                        width="56"
                        height="24"
                        alt="EXN Home"
                        class="m-0 hidden h-auto w-full dark:flex"
                        src="./../../../../public/images/EXNLogo2.png" />
                </Link>
                <div class="relative left-[6px] flex w-full justify-around">
                    <div
                        class="flex items-center"
                        aria-label="Explore"
                        alt="Explore"
                        @click="handleResetAndNavigate">
                        <font-awesome-icon
                            :icon="['fas', 'magnifying-glass']"
                            class="mx-auto h-[23px] w-[23px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                    </div>
                    <Link
                        v-if="!props.creator.isCreator && isLoggedIn"
                        :href="route('profile.wallet')"
                        aria-label="Wallet"
                        alt="Wallet"
                        class="flex items-center">
                        <font-awesome-icon
                            :icon="['fas', 'coins']"
                            class="mx-auto h-[23px] w-[23px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                    </Link>
                    <div
                        v-if="!isLoggedIn"
                        @click="emit('showFlyout')"
                        aria-label="Wallet"
                        alt="Wallet"
                        class="flex items-center">
                        <font-awesome-icon
                            :icon="['fas', 'coins']"
                            class="mx-auto h-[23px] w-[23px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                    </div>
                    <Link
                        v-if="props.creator.isCreator"
                        aria-label="Upload"
                        alt="Upload"
                        :href="route('creator.start')"
                        class="flex items-center">
                        <font-awesome-icon
                            :icon="['fas', 'circle-arrow-up']"
                            class="mx-auto h-[23px] w-[23px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                    </Link>
                    <div class="relative flex items-center">
                        <button
                            @click="isLoggedIn ? toggleMenu($event) : emit('goMessagePage')"
                            type="button"
                            aria-label="Message"
                            alt="Message"
                            class="relative inline-flex items-center rounded-md border border-transparent text-sm font-medium leading-4 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700 focus:outline-none">
                            <font-awesome-icon
                                :icon="['fas', 'message-plus']"
                                class="mx-auto h-[23px] w-[23px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                            <div
                                v-if="
                                    isLoggedIn &&
                                    (profile?.conversations_count > 0 ||
                                        profile?.notifications_count > 0)
                                "
                                class="absolute left-[18px] top-[-5px] h-[10px] w-[10px] rounded-full bg-alertred"></div>
                        </button>
                        <div
                            v-if="isMenuOpen"
                            class="menu flex w-[152px] flex-col space-y-[16px] text-[14px] text-white">
                            <!-- Message Link -->
                            <a
                                :href="route('chatify', { origin: pathName })"
                                class="menu-item relative flex items-center text-left"
                                aria-label="Messages"
                                alt="Messages"
                                @click="toggleMenu">
                                <font-awesome-icon
                                    :icon="['fas', 'comment']"
                                    class="h-[20px] w-[20px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                                <div
                                    v-if="profile?.conversations_count > 0"
                                    class="absolute left-[11px] top-[0px] h-[16px] w-[16px] rounded-full bg-alertred">
                                    <span
                                        class="absolute left-[1px] top-0 h-[16px] w-[15px] text-center text-[10px] font-medium leading-[16px] text-white"
                                        >{{
                                            profile?.conversations_count > 9
                                                ? '+9'
                                                : profile?.conversations_count
                                        }}</span
                                    >
                                </div>
                                <span class="pl-[9px] text-[14px] font-medium text-white"
                                    >Messages</span
                                >
                            </a>
                            <!-- Notification Link -->
                            <Link
                                :href="route('notifications')"
                                class="menu-item relative flex items-center text-left"
                                aria-label="Notifications"
                                alt="Notifications"
                                @click="toggleMenu">
                                <font-awesome-icon
                                    :icon="['fas', 'bell']"
                                    class="h-[20px] w-[20px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                                <div
                                    v-if="profile?.notifications_count > 0"
                                    class="absolute left-[11px] top-[0px] h-[16px] w-[16px] rounded-full bg-alertred">
                                    <span
                                        class="absolute left-[1px] top-0 h-[16px] w-[15px] text-center text-[10px] font-medium leading-[16px] text-white"
                                        >{{
                                            profile?.notifications_count > 9
                                                ? '+9'
                                                : profile?.notifications_count
                                        }}</span
                                    >
                                </div>
                                <span class="pl-[9px] text-[14px] font-medium text-white"
                                    >Notifications</span
                                >
                            </Link>
                        </div>
                    </div>

                    <Link
                        :href="
                            isLoggedIn
                                ? route('profile.show', [$page.props.auth.user.profile_url])
                                : route('access', { type: 'login' })
                        "
                        class="inline-flex items-center rounded-md">
                        <button
                            type="button"
                            aria-label="Profile"
                            alt="Profile"
                            class="inline-flex h-[30px] w-[30px] items-center rounded-md text-sm font-medium leading-4 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700 focus:outline-none">
                            <img
                                v-if="avatarImage && isLoggedIn"
                                :src="avatarImage"
                                class="h-[30px] w-[30px] rounded-full" />
                            <div v-else>
                                <font-awesome-icon
                                    :icon="['fa-solid', 'circle-user']"
                                    class="mx-auto h-7 w-7 cursor-pointer text-white hover:text-gray-400 dark:text-white" />
                            </div>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
        <div class="black-bg fixed bottom-0 z-[10] h-[30px] w-full bg-black"></div>

        <div v-if="pwaDetected" class="fixed bottom-0 z-[10] h-[30px] w-full bg-black"></div>
    </footer>
</template>
<script setup>
    import { ref, onMounted, onBeforeUnmount } from 'vue'
    import { Link } from '@inertiajs/vue3'
    import ApplicationMobileLogo from '@/Components/ApplicationMobileLogo.vue'
    import { usePage, router } from '@inertiajs/vue3'
    import useSWRV from 'swrv'
    import { useTagsStore } from '@/store/tagsStore'
    const tagsStore = useTagsStore()
    function handleResetAndNavigate() {
        //console.log('reset and navigate')
        tagsStore.resetStore() // Reset the store
        router.visit(route('explore.index'))
    }
    const pathName = window.location.pathname
    const props = usePage().props
    const avatarImage = usePage().props.avatarImage
    const { isLoggedIn, isCreator } = defineProps({
        isLoggedIn: {
            type: Boolean,
            default: false,
        },
    })
    const emit = defineEmits(['showFlyout', 'goMessagePage'])
    const pwaDetected = ref(false)

    const fetcher = url => axios.get(url).then(res => res.data)
    const { data: profile } = useSWRV(isLoggedIn ? '/profile/resource' : null, fetcher)

    if (isPWA()) {
        pwaDetected.value = true
        console.log('StandAlone App detected.')
    }
    function isPWA() {
        return (
            window.matchMedia('(display-mode: standalone)').matches ||
            window.navigator.standalone ||
            document.referrer.includes('android-app://')
        )
    }
    let blackbarAdded = ref('bottom-[-2px]')

    function isIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    }

    function isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    }
    const outsideClickListener = event => {
        // Check if the clicked element is not the menu or a descendant of it
        if (!event.target.closest('.menu')) {
            isMenuOpen.value = false
        }
    }

    onMounted(() => {
        isPWA()

        document.addEventListener('click', outsideClickListener)
    })

    onBeforeUnmount(() => {
        document.removeEventListener('click', outsideClickListener)
    })
    let isMenuOpen = ref(false)

    const toggleMenu = event => {
        event.stopPropagation() // Stop event from bubbling up
        isMenuOpen.value = !isMenuOpen.value
    }
    const isTabBarMode = ref(false)
    let previousHeight = window.innerHeight

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    onMounted(() => {})

    onBeforeUnmount(() => {})
</script>
<style scoped>
    .menu {
        padding: 18px;
        position: absolute;
        bottom: 60px; /* Adjust this value to your design */
        right: -60px; /* Adjust this value to your design */
        background-color: rgba(0, 0, 0, 0.5); /* Adjust styling as needed */
        border-radius: 6px;

        z-index: 1000; /* Make sure the menu is above other elements */
    }
    .black-bg {
        bottom: -15px;
        height: calc(env(safe-area-inset-bottom) + 15px);
    }
    #mobileFooter {
        height: calc(55px + env(safe-area-inset-bottom));
        padding-bottom: env(safe-area-inset-bottom);
    }
</style>
